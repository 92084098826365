import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const CloseIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootCloseIcon, className);

  return (
    <svg
      className={classes}
      width="15"
      height="15"
      viewBox="0 0 17 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16 1L1 16" />
      <path d="M16 16L1 1" />
    </svg>
  );
};

const { string } = PropTypes;

CloseIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

CloseIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default CloseIcon;
