import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, shape, string } from 'prop-types';
import pickBy from 'lodash/pickBy';
import classNames from 'classnames';

import appSettings from '../../../config/settings';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';

import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords, isOriginInUse } from '../../../util/search';
import { parse, stringify } from '../../../util/urlHelpers';
import { createResourceLocatorString, matchPathname, pathByRouteName } from '../../../util/routes';
import { propTypes } from '../../../util/types';
import {
  Avatar,
  Button,
  LimitedAccessBanner,
  LinkedLogo,
  NamedLink,
  Modal,
  ModalMissingInformation,
} from '../../../components';

import MenuIcon from './MenuIcon';
import SearchIcon from './SearchIcon';
import CloseIcon from './CloseIcon';
import TopbarSearchForm from './TopbarSearchForm/TopbarSearchForm';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';

import css from './Topbar.module.css';

/* Not used since always using mobile menu */
const MAX_MOBILE_SCREEN_WIDTH = 1024;

const redirectToURLWithModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const searchString = `?${stringify({ [modalStateParam]: 'open', ...parse(search) })}`;
  history.push(`${pathname}${searchString}`, state);
};

const redirectToURLWithoutModalState = (props, modalStateParam) => {
  const { history, location } = props;
  const { pathname, search, state } = location;
  const queryParams = pickBy(parse(search), (v, k) => {
    return k !== modalStateParam;
  });
  const stringified = stringify(queryParams);
  const searchString = stringified ? `?${stringified}` : '';
  history.push(`${pathname}${searchString}`, state);
};

const isPrimary = o => o.group === 'primary';
const isSecondary = o => o.group === 'secondary';
const compareGroups = (a, b) => {
  const isAHigherGroupThanB = isPrimary(a) && isSecondary(b);
  const isALesserGroupThanB = isSecondary(a) && isPrimary(b);
  // Note: sort order is stable in JS
  return isAHigherGroupThanB ? -1 : isALesserGroupThanB ? 1 : 0;
};
// Returns links in order where primary links are returned first
const sortCustomLinks = customLinks => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.sort(compareGroups);
};

// Resolves in-app links against route configuration
const getResolvedCustomLinks = (customLinks, routeConfiguration) => {
  const links = Array.isArray(customLinks) ? customLinks : [];
  return links.map(linkConfig => {
    const { type, href } = linkConfig;
    const isInternalLink = type === 'internal' || href.charAt(0) === '/';
    if (isInternalLink) {
      // Internal link
      const testURL = new URL('http://my.marketplace.com' + href);
      const matchedRoutes = matchPathname(testURL.pathname, routeConfiguration);
      if (matchedRoutes.length > 0) {
        const found = matchedRoutes[0];
        const to = { search: testURL.search, hash: testURL.hash };
        return {
          ...linkConfig,
          route: {
            name: found.route?.name,
            params: found.params,
            to,
          },
        };
      }
    }
    return linkConfig;
  });
};

const isCMSPage = found =>
  found.route?.name === 'CMSPage' ? `CMSPage:${found.params?.pageId}` : null;
const isInboxPage = found =>
  found.route?.name === 'InboxPage' ? `InboxPage:${found.params?.tab}` : null;
// Find the name of the current route/pathname.
// It's used as handle for currentPage check.
const getResolvedCurrentPage = (location, routeConfiguration) => {
  const matchedRoutes = matchPathname(location.pathname, routeConfiguration);
  if (matchedRoutes.length > 0) {
    const found = matchedRoutes[0];
    const cmsPageName = isCMSPage(found);
    const inboxPageName = isInboxPage(found);
    return cmsPageName ? cmsPageName : inboxPageName ? inboxPageName : `${found.route?.name}`;
  }
};

const GenericError = props => {
  const { show } = props;
  const classes = classNames(css.genericError, {
    [css.genericErrorVisible]: show,
  });
  return (
    <div className={classes}>
      <div className={css.genericErrorContent}>
        <p className={css.genericErrorText}>
          <FormattedMessage id="Topbar.genericError" />
        </p>
      </div>
    </div>
  );
};

GenericError.propTypes = {
  show: bool.isRequired,
};

class TopbarComponent extends Component {
  constructor(props) {
    super(props);
    this.handleMobileMenuOpen = this.toggleMobileMenu.bind(this);
    this.handleMobileSearchOpen = this.toggleSearchMenu.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    // // Initialize state for search phrase
    // this.state = {
    //   searchPhrase: '', // New state for the search phrase
    // };
  }

  toggleMobileMenu(status) {
    if (status) {
      redirectToURLWithoutModalState(this.props, 'mobilemenu');
    } else {
      redirectToURLWithModalState(this.props, 'mobilemenu');
    }
  }

  toggleSearchMenu(status) {
    if (status) {
      redirectToURLWithoutModalState(this.props, 'mobilesearch');
    } else {
      redirectToURLWithModalState(this.props, 'mobilesearch');
    }
  }

  handleSubmit(values) {
    const { currentSearchParams } = this.props;
    const { history, config, routeConfiguration } = this.props;

    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location;
      const { origin, bounds } = selectedPlace;
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const searchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  }

  handleLogout() {
    const { onLogout, history, routeConfiguration } = this.props;
    onLogout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out'); // eslint-disable-line
    });
  }

  render() {
    const {
      className,
      rootClassName,
      desktopClassName,
      mobileRootClassName,
      mobileClassName,
      isAuthenticated,
      isLoggedInAs,
      authScopes,
      authInProgress,
      currentUser,
      currentUserHasListings,
      currentUserHasOrders,
      currentPage,
      notificationCount,
      intl,
      location,
      onManageDisableScrolling,
      onResendVerificationEmail,
      sendVerificationEmailInProgress,
      sendVerificationEmailError,
      showGenericError,
      config,
      routeConfiguration,
    } = this.props;

    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // Custom links are sorted so that group="primary" are always at the beginning of the list.
    const sortedCustomLinks = sortCustomLinks(config.topbar?.customLinks);
    const customLinks = getResolvedCustomLinks(sortedCustomLinks, routeConfiguration);
    const categories = config.categoryConfiguration?.categories;
    const resolvedCurrentPage = currentPage || getResolvedCurrentPage(location, routeConfiguration);

    const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

    /* Not used since always using mobile menu */
    const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
    const isMobileLayout = hasMatchMedia
      ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
      : true;
    // const isMobileMenuOpen = isMobileLayout && mobilemenu === 'open';
    // const isMobileSearchOpen = isMobileLayout && mobilesearch === 'open';

    const isMobileMenuOpen = mobilemenu === 'open';
    const isMobileSearchOpen = mobilesearch === 'open';
    const mobileMenu = (
      <TopbarMobileMenu
        isAuthenticated={isAuthenticated}
        currentUserHasListings={currentUserHasListings}
        currentUser={currentUser}
        onLogout={this.handleLogout}
        notificationCount={notificationCount}
        currentPage={resolvedCurrentPage}
        categories={categories}
        customLinks={customLinks}
      />
    );
    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }

      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
              search: address,
              selectedPlace: { address, origin, bounds },
            }
          : null,
      };
    };

    const initialSearchFormValues = topbarSearcInitialValues();

    console.log(isMobileLayout);

    const classes = classNames(rootClassName || css.root, className);

    const LoginUser = () => {
      return isAuthenticated && currentUser ? (
        <NamedLink className={classNames(css.navigationLink)} name="ProfileSettingsPage">
          <Avatar className={css.avatar} user={currentUser} disableProfileLink />
          {notificationDot}
        </NamedLink>
      ) : (
        <NamedLink className={css.navigationLink} name="LoginPage">
          <Button rootClassName={css.menu}>
            <FormattedMessage id="Topbar.login" />
          </Button>
        </NamedLink>
      );
    };

    const SearchButton = initialSearchFormValues => {
      if (initialSearchFormValues.keywords !== undefined) {
        return (
          <>
            <Button
              rootClassName={css.searchMenuActive}
              onClick={event => this.toggleSearchMenu(event, isMobileSearchOpen)}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              {isMobileSearchOpen ? (
                <CloseIcon className={css.menuIconActive} />
              ) : (
                <>
                  <SearchIcon className={css.menuIconActive} /> {initialSearchFormValues.keywords}
                </>
              )}
            </Button>
            <Button rootClassName={css.searchClearButton} onClick={() => this.handleSubmit()}>
              <CloseIcon className={css.closeMenuIcon} />
            </Button>
          </>
        );
      } else {
        return (
          <Button
            rootClassName={css.searchMenu}
            onClick={() => this.toggleSearchMenu(isMobileSearchOpen)}
            title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
          >
            {isMobileSearchOpen ? (
              <CloseIcon className={css.closeMenuIcon} />
            ) : (
              <SearchIcon className={css.searchMenuIcon} />
            )}
          </Button>
        );
      }
    };

    return (
      <div className={classes}>
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          isLoggedInAs={isLoggedInAs}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={this.handleLogout}
          currentPage={resolvedCurrentPage}
        />
        <div className={classNames(mobileRootClassName || css.container, mobileClassName)}>
          <div className={css.containerLeft}>
            {/* TODO: Activate after BETA stage */}
            {/* <Button
              rootClassName={css.menu}
              onClick={() => this.toggleMobileMenu(isMobileMenuOpen)}
              title={intl.formatMessage({ id: 'Topbar.menuIcon' })}
            >
              {isMobileMenuOpen ? 'Hide Menu' : 'Show Menu'}
              {notificationDot}
            </Button>
            {SearchButton(initialSearchFormValues)} */}
            {/* TODO: Remove after BETA Stage */}
            <NamedLink className={css.navigationLink} name="NewListingPage">
              <Button rootClassName={css.menu} className={css.createListingButton}>
                <FormattedMessage id="Topbar.newListing" />
              </Button>
            </NamedLink>
          </div>
          <div className={css.containerMiddle}>
            {resolvedCurrentPage !== 'LandingPage' && !isMobileLayout && (
              <LinkedLogo
                layout={'desktop'}
                alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                linkToExternalSite={config?.topbar?.logoLink}
              />
            )}
            {isMobileLayout && (
              <LinkedLogo
                layout={'mobile'}
                alt={intl.formatMessage({ id: 'Topbar.logoIcon' })}
                linkToExternalSite={config?.topbar?.logoLink}
              />
            )}
          </div>
          <div className={css.containerRight}>
            <LoginUser />
          </div>
        </div>
        {/* Toggle Mobile Menu on click */}
        {isMobileMenuOpen && (
          <>
            <div className={css.searchContainer}>
              <TopbarSearchForm
                onSubmit={this.handleSubmit}
                initialValues={initialSearchFormValues}
                isMobile
                appConfig={config}
              />
              {/* <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p> */}
            </div>
            {authInProgress ? null : mobileMenu}
          </>
        )}
        {isMobileSearchOpen && (
          <div className={css.searchContainer}>
            <TopbarSearchForm
              onSubmit={this.handleSubmit}
              initialValues={initialSearchFormValues}
              isMobile
              appConfig={config}
            />
            {/* <p className={css.mobileHelp}>
              <FormattedMessage id="Topbar.mobileSearchHelp" />
            </p> */}
          </div>
        )}
        <ModalMissingInformation
          id="MissingInformationReminder"
          containerClassName={css.missingInformationModal}
          currentUser={currentUser}
          currentUserHasListings={currentUserHasListings}
          currentUserHasOrders={currentUserHasOrders}
          location={location}
          onManageDisableScrolling={onManageDisableScrolling}
          onResendVerificationEmail={onResendVerificationEmail}
          sendVerificationEmailInProgress={sendVerificationEmailInProgress}
          sendVerificationEmailError={sendVerificationEmailError}
        />
        <GenericError show={showGenericError} />
      </div>
    );
  }
}

TopbarComponent.defaultProps = {
  className: null,
  rootClassName: null,
  desktopClassName: null,
  mobileRootClassName: null,
  mobileClassName: null,
  notificationCount: 0,
  currentUser: null,
  currentUserHasOrders: null,
  currentPage: null,
  sendVerificationEmailError: null,
  authScopes: [],
};

TopbarComponent.propTypes = {
  className: string,
  rootClassName: string,
  desktopClassName: string,
  mobileRootClassName: string,
  mobileClassName: string,
  isAuthenticated: bool.isRequired,
  isLoggedInAs: bool.isRequired,
  authScopes: array,
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  currentUserHasOrders: bool,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onResendVerificationEmail: func.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  showGenericError: bool.isRequired,

  // These are passed from Page to keep Topbar rendering aware of location changes
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const Topbar = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  return (
    <TopbarComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      {...props}
    />
  );
};

export default Topbar;
