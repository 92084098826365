import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Topbar.module.css';

const SearchIcon = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg
      className={classes}
      width="17"
      height="17"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.875 10.375C8.36028 10.375 10.375 8.36028 10.375 5.875C10.375 3.38972 8.36028 1.375 5.875 1.375C3.38972 1.375 1.375 3.38972 1.375 5.875C1.375 8.36028 3.38972 10.375 5.875 10.375Z" />
      <path d="M12.6251 12.6249L9.05505 9.05493" />
    </svg>
  );
};

const { string } = PropTypes;

SearchIcon.defaultProps = {
  className: null,
  rootClassName: null,
};

SearchIcon.propTypes = {
  className: string,
  rootClassName: string,
};

export default SearchIcon;
