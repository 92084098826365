/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { propTypes } from '../../../../util/types';
import { ensureCurrentUser } from '../../../../util/data';

import {
  ExternalLink,
  Button,
  NamedLink,
  NotificationBadge,
  Heading,
} from '../../../../components';
import LoginForm from '../../../AuthenticationPage/LoginForm/LoginForm';
import { login } from '../../../../ducks/auth.duck';

import css from './TopbarMobileMenu.module.css';

const CategoryLinkComponent = ({ categories, currentPage }) => {
  const { name, id } = categories;
  const slug = `?pub_categoryLevel1=${id}`;

  return (
    <NamedLink name={`SearchPage`} to={{ search: slug }} className={css.navigationLink}>
      {name}
    </NamedLink>
  );
};

const CustomLinkComponent = ({ linkConfig, currentPage }) => {
  const { group, text, type, href, route } = linkConfig;
  const getCurrentPageClass = page => {
    const hasPageName = name => currentPage?.indexOf(name) === 0;
    const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
    const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
    const isCurrentPage = currentPage === page;

    return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
      ? css.currentPage
      : null;
  };

  // Note: if the config contains 'route' keyword,
  // then in-app linking config has been resolved already.
  if (type === 'internal' && route) {
    // Internal link
    const { name, params, to } = route || {};
    const className = classNames(css.navigationLink, getCurrentPageClass(name));
    return (
      <NamedLink name={name} params={params} to={to} className={className}>
        <span className={css.menuItemBorder} />
        {text}
      </NamedLink>
    );
  }
  return (
    <ExternalLink href={href} className={css.navigationLink}>
      <span className={css.menuItemBorder} />
      {text}
    </ExternalLink>
  );
};

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    categories,
    customLinks,
    onLogout,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const dispatch = useDispatch();

  const extraLinks = customLinks.map(linkConfig => {
    return (
      <CustomLinkComponent
        key={linkConfig.text}
        linkConfig={linkConfig}
        currentPage={currentPage}
      />
    );
  });

  const handleLoginSubmit = values => {
    console.log(values);
    dispatch(login(values.email, values.password));
  };

  const listingCategories = categories.map(category => {
    return (
      <CategoryLinkComponent key={category.id} categories={category} currentPage={currentPage} />
    );
  });

  const renderUnauthenticatedContent = () => {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <>
        <div className={css.authenticationGreeting}>
          <FormattedMessage
            id="TopbarMobileMenu.unauthorizedGreeting"
            values={{ lineBreak: <br />, signupOrLogin }}
          />
        </div>
        <div className={css.spacer} />
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </>
    );
  };

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    const isInboxPage = currentPage?.indexOf('InboxPage') === 0 && page?.indexOf('InboxPage') === 0;
    return currentPage === page || isAccountSettingsPage || isInboxPage ? css.currentPage : null;
  };
  const inboxTab = currentUserHasListings ? 'sales' : 'orders';

  const signupLink = (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <FormattedMessage id="TopbarMobileMenu.signupLink" />
    </NamedLink>
  );

  return (
    <div className={css.root}>
      <div className={css.spacer} />
      <div className={css.spacer} />
      <div className={css.content}>
        <div className={css.contentColumn}>
          <div className={css.customLinksWrapper}>{extraLinks}</div>
        </div>
        <div className={css.contentColumn}>
          <div className={css.customLinksWrapper}>{listingCategories}</div>
        </div>
        <div className={css.contentColumn} style={{ minWidth: '50%', maxHeight: '400px' }}>
          {!isAuthenticated ? (
            <>
              <Heading className="h3" style={{ fontWeight: 'normal', padding: 0, margin: 0 }}>
                <FormattedMessage id="TopbarMobileMenu.loginPrompt" />
              </Heading>
              <p>Don't have an account? {signupLink}. It takes less than a minute.</p>
              <LoginForm onSubmit={handleLoginSubmit} />
            </>
          ) : (
            <>
              {/* <AvatarLarge className={css.avatar} user={currentUser} /> */}
              <span className={css.greeting}>
                <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
              </span>
              <div className={css.spacer} />
              <>
                <span className={css.recentActivity}>Recent activity</span>
              </>
              <div className={css.content} style={{ margin: 0 }}>
                <div className={css.contentColumn}>
                  <div className={css.statBox} style={{ backgroundColor: '#E8E8E8' }}>
                    Statistic 1
                  </div>
                </div>
                <div className={css.contentColumn}>
                  <div
                    className={css.statBox}
                    style={{ backgroundColor: '#4040FB', color: '#ffffff' }}
                  >
                    Statistic 2
                  </div>
                </div>
                <div className={css.contentColumn}>
                  <div className={css.statBox} style={{ backgroundColor: '#7AE1A9' }}>
                    Statistic 3
                  </div>
                </div>
              </div>

              <div className={css.spacer} />
              <div className={css.content} style={{ margin: 0 }}>
                <div className={css.contentColumn}>
                  <NamedLink
                    className={classNames(
                      css.navigationLink,
                      currentPageClass(`InboxPage:${inboxTab}`)
                    )}
                    name="InboxPage"
                    params={{ tab: inboxTab }}
                  >
                    <Button className={css.accountButton}>
                      <FormattedMessage id="TopbarMobileMenu.inboxLink" />
                      {notificationCountBadge}
                    </Button>
                  </NamedLink>
                </div>
                <div className={css.contentColumn}>
                  <NamedLink
                    className={classNames(
                      css.navigationLink,
                      currentPageClass('ManageListingsPage')
                    )}
                    name="ManageListingsPage"
                  >
                    <Button className={css.accountButton}>
                      <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
                    </Button>
                  </NamedLink>
                </div>
                <div className={css.contentColumn}>
                  <NamedLink
                    className={classNames(
                      css.navigationLink,
                      currentPageClass('ProfileSettingsPage')
                    )}
                    name="ProfileSettingsPage"
                  >
                    <Button className={css.accountButton}>
                      <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
                    </Button>
                  </NamedLink>
                </div>
                {/* <div>
                  <NamedLink
                    className={classNames(
                      css.navigationLink,
                      currentPageClass('AccountSettingsPage')
                    )}
                    name="AccountSettingsPage"
                  >
                    <Button className={css.accountButton}>
                      <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
                    </Button>
                  </NamedLink>
                </div> */}
                <div className={css.contentColumn}>
                  <Button className={css.accountButton} onClick={onLogout}>
                    <FormattedMessage id="TopbarMobileMenu.logoutLink" />
                  </Button>
                </div>
              </div>
            </>
          )}

          <div className={css.spacer} />
        </div>
      </div>
      {/* <div className={css.footer}>
        <NamedLink className={css.createNewListingLink} name="NewListingPage">
          <FormattedMessage id="TopbarMobileMenu.newListingLink" />
        </NamedLink>
      </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
